.containerDashboard {
    text-align: center;
    caret-color: transparent;
}

.image-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #000; 
    width: 1150px;
    height: 750px;
    margin-left: 100px;
}

.image-item {
    width: 100%;
}

.grid-image {
    cursor: pointer;
    transition: transform 0.3s ease;
    margin-top: 20px;
}

.grid-image:hover {
    transform: scale(1.05);
}
