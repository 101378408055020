/* DemographicRadioButton.css */
.radio-option {
  display: flex;
  flex-direction: column;
}

.radio-option label {
  position: relative;
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 10px; 
  display: block;
  user-select: none; 
  padding-left: 30px; 
}

.radio-option input[type="radio"] {
  opacity: 0;
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  margin: 0;
  cursor: pointer;
  z-index: 2;
}

.radio-option label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #aaa;
  border-radius: 50%;
  background: white;
  transition: background-color 0.2s, border-color 0.2s;
}

.radio-option input[type="radio"]:checked + label::before {
  background-color: #2196F3;
  border-color: #2196F3;
}

.dropdown {
  width: 100%; 
  height: 40px; 
  font-size: 16px; 
  padding: 5px 10px; 
  border: 1px solid #ccc; 
  border-radius: 5px; 
  background-color: white; 
  cursor: pointer; 
}

.dropdown option {
  padding: 5px;
  font-size: 16px; 
}