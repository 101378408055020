.container {
    width: 90%;
    max-width: 1350px;
    min-height: 100vh;
    /* border: 2px solid #333; */
    padding: 20px;
    margin: 30px auto;
    box-shadow: 0 4px 8px #0000001a;
    box-sizing: border-box;
    text-align: left;
    font-family: Arial, sans-serif;
    caret-color: transparent;
}

/* Adjustments for different screen sizes */
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 1024px) {
    .container {
        max-width: 960px;
    }
}

.ucflogo {
    max-width: 100%;
    height: auto;
}


@media (min-width: 768px) {
    button {
        max-width: 200px;
        font-size: 1.25rem;
    }
}

.button {
    font-size: 18px;
    width: 150px;
    padding: 12px 24px;
    border-radius: 8px;
    cursor: pointer;
    display: block;
    margin: 20px auto;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
    border: none;
}

.button.enabled {
    background-color: #4CAF50;
    color: white;
}

.button:disabled {
    background-color: #9E9E9E;
    color: #BDBDBD;
    cursor: not-allowed;
}

div[name="instructions"] p {
    font-size: 16px;
    margin: 0 0 15px;
    text-align: left;
}

div[name="instructionsh3"] h3 {
    font-size: 16px;
    margin: 0 0 15px;
    text-align: left;
}

div[name="instructions"] li {
    font-size: 16px;
    margin-bottom: 10px;
}

/* div[name="instructions"] img {
    display: block;
    margin: 0 auto;
} */

.form-container {
    font-size: 18px;
}

.LogoStyleImage {
    caret-color: transparent;
    text-align: center;
}

.images-container {
    text-align: left;
    caret-color: transparent;
}

.instructionsred {
    padding: 15px;
    border-left: 4px solid #0e0303;
    background-color: #f9f9f9;
    font-family: Arial;
}

.instructionsred p {
    margin: 10px 0;
}

.instructionsred .contact-heading {
    font-weight: bold;
    color: #0e0303;
    font-family: Arial;
}

.instructionsred .contact-info {
    margin-left: 20px;
}

.instructionsred .email,
.instructionsred .phone {
    font-style: italic;
    color: #0e0303;
    font-family: Arial;
}

.instructionsFL {
    text-align: left;
    /* padding: 50px; */
}

.bricksimage {
    text-align: left;
    height: 300px;
    width: 550px;
}

.text-input {
    width: 200px;
    height: 30px;
    font-size: 16px;
}

.text-input-larger {
    width: 500px;
    height: 30px;
    font-size: 16px;
}

.text-input-much-larger {
    width: 1305px;
    height: 80px;
    font-size: 16px;
}

.button-green {
    background-color: green;
    color: white;
}

.button-grey {
    background-color: grey;
    color: white;
}

.input-error {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 0.9em;
}

.required-star {
    color: red;
    margin-left: 4px; 
    font-size: 1.5em;
    vertical-align: middle;
}