.question-block {
  margin-bottom: 20px;
}

.question-image-container {
    text-align: center; /* Centers all inline content within the container */
    margin-bottom: 20px; /* Adds space below the container */
  }
  
  .question-image-container img {
    display: inline-block; /* This makes the image respect text-align from its parent */
    /* max-width: 70%; Ensures the image doesn’t exceed its container’s width */
    /* height: auto; Maintains the aspect ratio of the image */
    object-fit: cover; /* Ensures the image covers the specified area, may crop as needed */
    margin: 0 auto 20px; /* Center the image horizontally and add margin below */
    width: 120px;
    height: 120px;
  }

.answers-table {
  border-collapse: collapse; /* Eliminates the space between the borders */
  margin-left: 30px; 
}

.answers-table th, .answers-table td {
  border: 1px solid black; /* Adds border to table headers and cells */
  text-align: center;
  padding: 10px;
}

.answers-table th {
  background-color: #f2f2f2; /* Light grey background for headers */
}

.answers-table th img {
  width: 100px;
  height: 100px;
  object-fit: cover; /* Ensures the image covers the specified area, may crop as needed */
  display: inline-block; /* This makes the image respect text-align from its parent */
  margin: auto; /* Center the image within the table header */
}

.sample_test img {
    width: 1200px; 
    height: 450px;
    float: left;  
    margin-right: 20px; 
}

.other_images img{
    display: block;
    width: 1000px;
    height: 200px;
}

.other_images2 img{
    display: block;
    width: 400px;
    height: 200px;
}
