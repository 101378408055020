/* ImageStyles.css */

.image-container {
  max-width: 80%;
  margin-left: 80px;
}

.image-container img {
  width: 600px;
  height: 400px;
}
