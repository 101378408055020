/* PaperFoldingStyle.css */
/* 
.Part2PaperFoldingTestImage {
    width: 50px;
    height: 50px;
  } */
  
  .image-container img {
    width: 250px;
    height: 150px;
  }